import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';
import { AWS_CONFIG } from './js/common/constants';
import './index.css';
import App from './js/app';

Amplify.configure({
    API: {
        endpoints: [
            {
                name: 'findQuestionnaireList',
                endpoint: AWS_CONFIG.apiGateway.CORE + '/quiz',
                region: AWS_CONFIG.apiGateway.REGION
            }
        ]
    }
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
