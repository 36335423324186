import React, { useState } from 'react';
import {useGet} from "./common/hook";
import Loader from "./components/loader";

export default function App() {
	const [data, loading] = useGet(
		'findQuestionnaireList',
		'/findQuestionnaireList'
	);

	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [showScore, setShowScore] = useState(false);
	const [score, setScore] = useState(0);

	const handleAnswerOptionClick = (isCorrect) => {
		if (isCorrect) {
			alert('Correct Answer');
			setScore(score + 1);
		} else {
			alert('Wrong Answer');
		}

		const nextQuestion = currentQuestion + 1;
		if (nextQuestion < data.length) {
			setCurrentQuestion(nextQuestion);
		} else {
			setShowScore(true);
		}
	};
	return (
		<div className='app'>
			{showScore ? (
				<div className='score-section'>
					You scored {score} out of {data.length}
				</div>
			) : (
				<>
					{loading ? (
						<Loader loading={loading} />
					) : (
						<>
							<div className='question-section'>
								<div className='question-count'>
									<span>Question {currentQuestion + 1}</span>/{data.length}
								</div>
								<div className='question-text'>{data[currentQuestion].questionText}</div>
							</div>
							<div className='answer-section'>
								{data[currentQuestion].answerOptions.map((answerOption) => (
									<button onClick={() => handleAnswerOptionClick(answerOption.isCorrect)}>{answerOption.answerText}</button>
								))}
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
}
